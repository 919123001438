if (document.querySelectorAll('[map-api-key]').length > 0) {
  let mapApiKey = document.querySelector('[map-api-key]').getAttribute('map-api-key')
  loadjs(['//maps.google.com/maps/api/js?key=' + mapApiKey, '/assets/scripts/mapStyles.js'], 'googlemaps')
}

loadjs([
  '/assets/scripts/functions.js',
  '/assets/scripts/app.js',
],
{
  async: false,
  success: () => {
    ready(() => {
      // Fix CSRF inputs
      if (query('[name="' + csrfTokenName + '"]').length > 0) {
        let csrfInputs = query('[name="' + csrfTokenName + '"]')
        csrfInputs.forEach(csrfInput => {
          csrfInput.value = csrfTokenValue
        })
      }

      deactivateLinks()
      vhFix()
      fixCallout()
      initNavigation()
      if (document.getElementsByClassName('section-slides').length > 0) {
        loadjs([
          '/assets/scripts/swipedetect.js',
        ],
        {
          success: () => {
            initSections()
          }
        })
      }
      if (document.getElementsByClassName('trainers').length > 0) {
        initTrainerInfo()
      }
      if (document.getElementsByClassName('slider').length > 0) {
        initializeSliders()
      }
      if (document.getElementsByClassName('image').length > 0) {
        initializeImageSlider()
      }
      if (document.getElementsByClassName('accordion').length > 0) {
        initializeAccordions()
      }

      // Move Footer temporarily and discuss options
      let lastSection = document.querySelector('.section-slides section:last-child')
      if (lastSection && !hasClass('section--slider', lastSection) && !hasClass('section--billboard', lastSection)) {
        let footer = document.querySelector('footer.footer')
        lastSection.insertBefore(footer, null)
      } else if (lastSection) {
        if (window.matchMedia('(max-width: 768px)').matches) {
          let footer = document.querySelector('footer.footer')
          let newSection = document.createElement('section')
          newSection.appendChild(footer)
          lastSection.parentNode.appendChild(newSection)
        } else {
          let sectionSlides = document.querySelector('.section-slides')
          if (sectionSlides) {
            addClass('section-slides--minus-footer', sectionSlides)
          }
        }
      }
    })
    loadjs([
      '/assets/scripts/lazysizes.js',
      '/assets/scripts/lazysizes-plugins/ls.bgset.js',
      '/assets/scripts/lazysizes-plugins/ls.respimg.min.js',
    ],
    {
      async: false,
      success: () => {
        document.addEventListener('lazybeforeunveil', e => {
          setSectionBrightness()
          let bg = e.target.getAttribute('data-bg')
          if (bg) {
            e.target.style.backgroundImage = 'url(' + bg + ')'
          }
          if (e.target.getAttribute('map-api-key')) {
            loadjs.ready('googlemaps',
            {
              success: () => {
                initMaps()
              }
            })
          }
        })
      }
    })
  }
})
